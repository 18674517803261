import axios from "axios";
const API_URL = process.env.VUE_APP_URL_API;

class ExclusaoService{
  async getAllFiscalSent() {
    return await axios.get(`${API_URL}/IcmsExclusao/getAllFiscalSent`);   
  };

  async getFilesSent(){

  }

  async checkFilesExclusao(nCodOp){
    let params = {
      NCodOp: nCodOp,
    };
    return await axios.get(`${API_URL}/IcmsExclusao/verifyNCodOp`, {params});
  }

  async getCliente(nCodOp,) {
    return await axios.get(`${API_URL}/IcmsExclusao/getCliente`,{
      params:{
        NCodOp: nCodOp,
      }
    });   
  }
  
  async updateResultExclusao(nCodOp) {
    return await axios.get(`${API_URL}/IcmsExclusao/calcularExclusao/`, {
        params: {
            NCodOp: nCodOp
        }
    });
  }
  async getResultExclusao(nCodOp) {
    return await axios.get(`${API_URL}/IcmsExclusao/getResultExclusao/`, {
        params: {
            NCodOp: nCodOp
        }
    });
  }
  async getSpedNotPaired(nCodOp) {
    return await axios.get(`${API_URL}/IcmsExclusao/getSpedNotPaired/`, {
        params: {
            NCodOp: nCodOp
        }
    });
  }

}

export default new ExclusaoService();