import axios from "axios";
const API_URL = process.env.VUE_APP_URL_API;

class UploadPisCofinsService {
    async getFilesSent(nCodOp){
        let params = {
            NCodOp: nCodOp,
        };
        return await axios.get(`${API_URL}/Insumos/getfilessended`, {params});
    }
    async uploadFiles(files, tese, nCodOp, ignorarCnpj = false, validar60Meses = true) {
        
        let file = new FormData();
        file.append("Files", files);
        file.append("NCodOp", nCodOp);
        file.append("IgnorarCNPJ", ignorarCnpj); //validar depois pra passar true na modal de confirmação
        file.append("Validar60Meses", validar60Meses);

        return await axios.post(`${API_URL}/IcmsExclusao/uploadFiles/`, file);
    }

    async getFiscalFiles(nCodOp) {
        let params = {
            NCodOp: nCodOp,
        };
        var filesSent = await axios.get(`${API_URL}/IcmsExclusao/GetFiscalFiles/`, {params});
        return filesSent;
    };

    async getContribuicoesFiles(nCodOp) {
        let params = {
            NCodOp: nCodOp,
        };
        var filesSent = await axios.get(`${API_URL}/IcmsExclusao/GetContribuicoesFiles/`, {params});
        return filesSent;
    }

    async clearAllFiscalFiles(nCodOp){
        try{
            let params = {
                NCodOp: nCodOp,
            };
            var heads = {crossDomain: true, "Content-Type": "application/json"}
            return await axios.delete(`${API_URL}/IcmsExclusao/deleteAllFiscalFiles/`, {params}, {headers:heads});
        }
        catch{
            console.error(error);
            throw error;
        }
    };
    async clearFiscalFiles(selected){
        try{
            let selectedString = selected.map(String).join("-")
            let params = {
                Selected: selectedString
            }
            var heads = {crossDomain: true, "Content-Type": "application/json"}
            return await axios.delete(`${API_URL}/IcmsExclusao/deleteFiscalFiles/`, {params}, {headers:heads});
        }
        catch{
            console.error(error);
            throw error;
        }
    };
    async clearAllContribuicoesFiles(nCodOp){
        let params = {
            NCodOp: nCodOp,
        };
        var heads= { crossDomain: true, "Content-Type": "application/json" }
        return await axios.delete(`${API_URL}/IcmsExclusao/deleteAllContribuicoesFiles`, {params}, {headers:heads});
    };
    async clearContribuicoesFiles(selected){
        try{
            let selectedString = selected.map(String).join("-")
            let params = {
                Selected: selectedString
            }
            var heads = {crossDomain: true, "Content-Type": "application/json"}
            return await axios.delete(`${API_URL}/IcmsExclusao/deleteContribuicoesFiles/`, {params}, {headers:heads});
        }
        catch{
            console.error(error);
            throw error;
        }
    };
}

export default new UploadPisCofinsService();