import axios from "axios";
const API_URL = process.env.VUE_APP_URL_API;

class RestituicaoService {
  
  async checkFilesRestituicao(nCodOp) {
    let params = {
      NCodOp: nCodOp,
    };
    return await axios.get(`${API_URL}/Restituicao/verifyNCodOp/`, {params});    
  }

  async getInsumos(nCodOp, passo) {
    return await axios.get(`${API_URL}/Restituicao/getinsumos/`, {
      params: {
        NCodOp: nCodOp,
        Passo: passo,
      }
    }); 
  }

  async getAllSpedSent() {
    return await axios.get(`${API_URL}/Restituicao/getAllSpedSent`);   
  }

  getF100Adicionados(nCodOp) {
    return axios.get(`${API_URL}/Restituicao/getf100add/`, {
      params: {
        NCodOp: nCodOp,
      }
    }); 
  }

  geti250(i050) {
    return axios.get(`${API_URL}/Restituicao/getrazao/`, {
      params: {
        CodCta: i050.codCta,
        NCodOp: i050.nCodOp,
      }
    }); 
  }

  async getOptionsSelects(){
    return await axios.get(`${API_URL}/Restituicao/getOptionsSelects/`);
  }

  getCodPart(nCodOp) {
    return axios.get(`${API_URL}/Restituicao/getcodparticipante/`, {
      params: {
        NCodOp: nCodOp,
      }
    }); 
  }

  async saveModalI250(i250sToRemove, nCodOp, codCta) {
    return axios.post(`${API_URL}/Restituicao/saveModalI250`, {
      I250sToRemove: i250sToRemove,
      CodCta: codCta,
      NCodOp: nCodOp,
    });
  }

  sendInsumo(insumo) {
    return axios.post(`${API_URL}/Restituicao/addf100`, {
      F100: insumo,
    });
  }

  sendAllInsumos(insumos) {
    return axios.post(`${API_URL}/Restituicao/addAllf100`, {
      F100sToAdd: insumos,
      NCodOp: insumos[0].NCodOp,
    });
  }

  removeInsumo(insumo) {
    return axios.post(`${API_URL}/Restituicao/removeInsumoF100`, {
      NCodOp: insumo.NCodOp,
      CodCta: insumo.CodCta,
      I050sToRemove: insumo.I050sToRemove,
    });
    
  }

  restoreI250(nCodOp) {
    return axios.post(`${API_URL}/Restituicao/restoreI250`, { NCodOp: nCodOp });
  }

  exportF100(nCodOp) {
    return axios.post(`${API_URL}/Restituicao/exportar/`, {NCodOp: nCodOp}); 
  }

  exportF100Notas(nCodOp) {
    return axios.post(`${API_URL}/Restituicao/exportarCsvNotas/`, {NCodOp: nCodOp}); 
  }

  async apagarI050Vazios(nCodOp) {
    let params = {
      NCodOp: nCodOp,
    };  
    var heads={ crossDomain: true, "Content-Type": "application/json" }
    return await axios.delete(`${API_URL}/Restituicao/deleteEmptyI050/`, {params}, {headers:heads});  
  }

  exportF100Cta(nCodOp) {
    return axios.post(`${API_URL}/Restituicao/exportarCsvCta/`, {NCodOp: nCodOp}); 
  }

  cadastrarNovoPart(dados) {
    return axios.post(`${API_URL}/Restituicao/cadastrar`, {
      codParticipante: dados.codParticipante,
      nome: dados.nome,
      codPais: dados.codPais,
      cnpj_cpf: dados.cnpj_cpf,
      codMun: dados.codMun,
    });
  }

}

export default new RestituicaoService();
