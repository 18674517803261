<template>
    <section>
        <div class="mx-2 pb-2" data-title=".dot-pulse">
            <div class="stage">
                <div class="dot-pulse"></div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "DotAnimation"
}
</script>

<style scoped>
.dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #6c757d;
    color: #6c757d;
    box-shadow: 9999px 0 0 -5px #6c757d;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: .25s;
}

.dot-pulse::before,
.dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #6c757d;
    color: #6c757d;
}

.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px #6c757d;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
}

.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px #6c757d;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: .5s;
}

@keyframes dotPulseBefore {
    0% {
        box-shadow: 9984px 0 0 -5px #6c757d;
    }

    30% {
        box-shadow: 9984px 0 0 2px #6c757d;
    }

    60%,
    100% {
        box-shadow: 9984px 0 0 -5px #6c757d;
    }
}

@keyframes dotPulse {
    0% {
        box-shadow: 9999px 0 0 -5px #6c757d;
    }

    30% {
        box-shadow: 9999px 0 0 2px #6c757d;
    }

    60%,
    100% {
        box-shadow: 9999px 0 0 -5px #6c757d;
    }
}

@keyframes dotPulseAfter {
    0% {
        box-shadow: 10014px 0 0 -5px #6c757d;
    }

    30% {
        box-shadow: 10014px 0 0 2px #6c757d;
    }

    60%,
    100% {
        box-shadow: 10014px 0 0 -5px #6c757d;
    }
}
</style>