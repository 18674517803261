import axios from "axios";
const API_URL = process.env.VUE_APP_URL_API;

class UploadInsumosService {
    
    async getInsumosFilesSent(nCodOp){
        let params = {
            NCodOp: nCodOp,
        };
        return await axios.get(`${API_URL}/Insumos/getInsumosFiles`, {params});
    }

    async getContabilFiles(nCodOp) {
        let params = {
            NCodOp: nCodOp,
        };
        var filesSent = await axios.get(`${API_URL}/Insumos/getAllContabilSent`, {params});
        return filesSent;
    };
    
    async getContribuicoesFiles(nCodOp) {
        let params = {
            NCodOp: nCodOp,
        };
        var filesSent = await axios.get(`${API_URL}/Insumos/getAllContribuicoesSent`, {params});
        return filesSent;

    }

    async uploadFiles(files, tese, nCodOp, ignorarCnpj, validate60Meses) {
        let file = new FormData();
        file.append("Files", files);
        file.append("NCodOp", nCodOp);
        file.append("IgnorarCNPJ", ignorarCnpj);
        file.append("Validar60Meses",validate60Meses );
        return await axios.post(`${API_URL}/${tese}/uploadFiles/`, file);
    }

    async uploadFilesDarf(files, tese, nCodOp, ignorarCnpj) {
        let file = new FormData();
        file.append("Files", files);
        file.append("NCodOp", nCodOp);
        file.append("IgnorarCNPJ", ignorarCnpj);
        return await axios.post(`${API_URL}/CreditoInss/uploadGuia/`, file);
    }

    async clearContabilFiles(selected){
        try{
            let selectedString = selected.map(String).join("-")
            let params = {
                Selected: selectedString
            }
            var heads={crossDomain: true, "Content-Type": "application/json" }
            return await axios.delete(`${API_URL}/Insumos/deleteContabilFiles/`, {params}, {headers:heads});
        }
        catch{
            console.error(error);
            throw error;
        }
    }
    
    async clearFiscalFiles(selected){
        try{
            let selectedString = selected.map(String).join("-")
            let params = {
                Selected: selectedString
            }
            var heads = {crossDomain: true, "Content-Type": "application/json"}
            return await axios.delete(`${API_URL}/IcmsExclusao/deleteFiscalFiles/`, {params}, {headers:heads});
        }
        catch{
            console.error(error);
            throw error;
        }
    };

    async clearContribuicoesFiles(selected){
        try{
            let selectedString = selected.map(String).join("-")
            let params = {
                Selected: selectedString
            }
            var heads = {crossDomain: true, "Content-Type": "application/json"}
            return await axios.delete(`${API_URL}/Insumos/deleteContribuicoesFiles/`, {params}, {headers:heads});
        }
        catch{
            console.error(error);
            throw error;
        }
    }

    async clearAllContabilFiles(nCodOp){
        let params = {
            NCodOp: nCodOp,
        };
        var heads={ crossDomain: true, "Content-Type": "application/json" }
        return await axios.delete(`${API_URL}/Insumos/deleteAllContabilFiles`, {params}, {headers:heads});
    }
    async clearAllContribuicoesFiles(nCodOp){
        let params = {
            NCodOp: nCodOp,
        };
        var heads={ crossDomain: true, "Content-Type": "application/json" }
        return await axios.delete(`${API_URL}/Insumos/deleteAllContribuicoesFiles`, {params}, {headers:heads});
    }
}

export default new UploadInsumosService();