<template>
    <section class="mt-5">    
        <h4 class="text-secondary mb-3"></h4>          
        <table class="table table-hover">
            <thead>
                <tr>
                    <th><label for=""> <input type="checkbox" v-model="selectAll" @click="select"> </label></th>
                    <th><strong>Id</strong></th>
                    <th><strong>Nome Empresa</strong></th>
                    <th><strong>Periodo</strong></th>
                    <th><strong>Ano</strong></th>
                    <th><strong>CNPJ</strong></th>
                    <th><strong>Data de Upload</strong></th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(file, index) in listFiles" :key="index" :id="`insumo${index}`" @click="addToSelected(file.id)">
                    <td>
                        <label for="">
                            <input type="checkbox" :value="file.id" v-model="selected">
                        </label>
                    </td>

                    <td>
                        {{ file.id }}
                    </td>

                    <td>
                        {{ file.nomeEmpresa }}
                    </td>

                    <td>
                        {{ file.periodoSped }}
                    </td>                        

                    <td>
                        {{ file.anoSped }}
                    </td>

                    <td>
                        {{ maskCnpj(file.cnpj) }}
                    </td>

                    <td>
                        {{ formatDate(file.data_Registro, file.dataEnvio) }}
                    </td>
                </tr>
            </tbody>
        </table>
        <!--
        <div class="text-center mt-5">
            <button :disabled="false" @click.stop.prevent="apagarTudo()" class="btn btn-outline-danger">
                Apagar todos os arquivos enviados
            </button>

            <button v-if="hasExSended" :disabled="false" @click.stop.prevent="apagarTudoExcel()" class="ml-5 btn btn-outline-danger">
                Apagar excel enviado
            </button>
            
            <button :disabled="false" @click.stop.prevent="apagarI050Vazios()" class="ml-5 btn btn-outline-info">
                Otimizar busca - Apagar I050 vazios
            </button>    
                           
        </div>
        -->
    </section>
</template>

<script>
import HelperService from "@/services/helper.service.js";
import UploadRestituicaoService from "@/services/uploadRestituicao.service";
import RestituicaoService from "@/services/teses/restituicao.service";
import UploadICMSExclusaoService from "@/services/uploadICMSExclusao.service";
import UploadInsumosService from "@/services/uploadInsumos.service";
import ExclusaoService from "@/services/teses/exclusao.service";
import Icon from '../Icon/icon.vue';

export default {
    name: "ListUploadFiles",

    props: { listFiles: Array, nCodOp: String, hasExSended: Boolean},

    components:{
        Icon,
    },

    data() {
        return{
            selected: [],
            selectAll: false,
        }
        
    },

    methods: {
        apagarTudo() {
            this.$swal({
                icon: "error",
                title: 'Deseja apagar TODOS os arquivos enviados?',
                html: `Esta ação não poderá ser desfeita.`,
                type: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, desejo continuar!',
                cancelButtonText: `Cancelar`,
            })
                .then((result) => {
                    if (result.value) {
                        this.$emit("showOverlay", true);
                        this.clearAllContribuicoesFiles(this.nCodOp);
                        }
                    }
                )
        },

        addToSelected(id){
            var index = this.selected.indexOf(id);

            if(this.selected.includes(id)){
                this.selected.splice(index, 1)
            }
            else {
                this.selected.push(id)
            }
        },

        getAllId(){
            this.listFiles.forEach(element => {
                this.selected.push(element.id)
            });
            return this.selected;
        },

        clearRestituicaoFiles(nCodOp){
            UploadRestituicaoService.apagarTudo(
                    nCodOp,
                ).then((response) => {
                    if (response.status == 200) {
                        this.listFiles = []
                        this.$emit("cleanList");
                        // HelperService.callMakeToast("Arquivos apagados com sucesso !!", "success");
                        this.$toast.open({message: 'Arquivos apagados com sucesso !!', type: 'success', duration: 2000});
                        this.selected = [];
                        return this.$emit("showOverlay", false);
                    }
                }).catch((error) => {
                    this.$emit("showOverlay", false);
                    console.log(error.response);
                    if (error.response.data.mensagem != null) {
                        return HelperService.callMakeModalWithBtnsAndTitle("Erro ao apagar !", error.response.data.mensagem[0], 'error');
                    }
                    // return HelperService.callMakeToast("Erro ao apagar", "error");
                    this.$toast.open({message: 'Erro ao apagar', type: 'error', duration: 2000});
                })
        },

        clearContribuicoesFiles(nCodOp){
            if(this.selected.length == 0 || this.selected.length == this.listFiles.length){
                this.$swal({
                icon: "error",
                title: 'Deseja apagar TODOS os arquivos contribuições?',
                html: `Esta ação não poderá ser desfeita.`,
                type: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Apagar tudo',
                cancelButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.value) {
                        this.$emit("showOverlay", true);
                        this.$emit('blockStepper')
                        UploadICMSExclusaoService.clearContribuicoesFiles(this.getAllId(),
                        ).then((response) => {
                            if (response.status == 200) {
                                this.listFiles = []
                                this.$emit("cleanList");
                                // HelperService.callMakeToast("Todos os arquivos contribuições foram apagados com sucesso!", "success");
                                this.$toast.open({message: 'Todos os arquivos contribuições foram apagados com sucesso!', type: 'success', duration: 2000});
                                this.selected = [];
                                this.$emit("validateSpedPairing");
                                return this.$emit("showOverlay", false);
                            }
                        }).catch((error) => {
                            this.$emit("showOverlay", false);
                            console.log(error.response);
                            if (error.response.data.mensagem != null) {
                                return HelperService.callMakeModalWithBtnsAndTitle("Erro ao apagar arquivos!", error.response.data.mensagem[0], 'error');
                            }
                            // return HelperService.callMakeToast("Erro ao apagar arquivos!", "error");
                            this.$toast.open({message: 'Erro ao apagar arquivos!', type: 'error', duration: 2000});
                        })
                        
                    }
                })
            } 
            else{
                this.$swal({
                icon: "error",
                title: 'Deseja apagar ' + this.selected.length + ' arquivos contribuições?',
                html: `Esta ação não poderá ser desfeita.`,
                type: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Apagar',
                cancelButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.value) {
                        this.$emit("showOverlay", true);
                        this.$emit('blockStepper')
                        UploadICMSExclusaoService.clearContribuicoesFiles(this.selected,
                        ).then((response) => {
                            if (response.status == 200) {
                                this.listFiles = []
                                this.$emit("cleanList");
                                // HelperService.callMakeToast("Os arquivos selecionados foram apagados com sucesso!", "success");
                                this.$toast.open({message: 'Os arquivos selecionados foram apagados com sucesso!', type: 'success', duration: 2000});
                                this.selected = [];
                                this.$emit("validateSpedPairing");
                                return this.$emit("showOverlay", false);
                            }
                        }).catch((error) => {
                            this.$emit("showOverlay", false);
                            console.log(error.response);
                            if (error.response.data.mensagem != null) {
                                return HelperService.callMakeModalWithBtnsAndTitle("Erro ao apagar arquivos!", error.response.data.mensagem[0], 'error');
                            }
                            // return HelperService.callMakeToast("Erro ao apagar arquivos!", "error");
                            this.$toast.open({message: 'Erro ao apagar arquivos!', type: 'error', duration: 2000});
                        })
                        
                    }
                })
            }
        },

        clearContribuicoesInsumosFiles(nCodOp){
            if(this.selected.length == 0 || this.selected.length == this.listFiles.length){
                this.$swal({
                icon: "error",
                title: 'Deseja apagar TODOS os arquivos contribuições?',
                html: `Esta ação não poderá ser desfeita.`,
                type: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Apagar tudo',
                cancelButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.value) {
                        this.$emit("showOverlay", true);
                        UploadInsumosService.clearContribuicoesFiles(this.getAllId(),
                        ).then((response) => {
                            if (response.status == 200) {
                                this.listFiles = []
                                this.$emit("cleanList");
                                // HelperService.callMakeToast("Todos os arquivos contribuições foram apagados com sucesso!", "success");
                                this.$toast.open({message: 'Todos os arquivos contribuições foram apagados com sucesso!', type: 'success', duration: 2000});
                                this.selected = [];
                                return this.$emit("showOverlay", false);
                            }
                        }).catch((error) => {
                            this.$emit("showOverlay", false);
                            console.log(error.response);
                            if (error.response.data.mensagem != null) {
                                return HelperService.callMakeModalWithBtnsAndTitle("Erro ao apagar arquivos!", error.response.data.mensagem[0], 'error');
                            }
                            // return HelperService.callMakeToast("Erro ao apagar arquivos!", "error");
                            this.$toast.open({message: 'Erro ao apagar arquivos!', type: 'error', duration: 2000});
                        })
                    }
                })
            } 
            else{
                this.$swal({
                icon: "error",
                title: 'Deseja apagar ' + this.selected.length + ' arquivos contribuições?',
                html: `Esta ação não poderá ser desfeita.`,
                type: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Apagar',
                cancelButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.value) {
                        this.$emit("showOverlay", true);
                        UploadInsumosService.clearContribuicoesFiles(this.selected,
                        ).then((response) => {
                            if (response.status == 200) {
                                this.listFiles = []
                                this.$emit("cleanList");
                                // HelperService.callMakeToast("Os arquivos selecionados foram apagados com sucesso!", "success");
                                this.$toast.open({message: 'Os arquivos selecionados foram apagados com sucesso!', type: 'success', duration: 2000});
                                this.selected = [];
                                return this.$emit("showOverlay", false);
                            }
                        }).catch((error) => {
                            this.$emit("showOverlay", false);
                            console.log(error.response);
                            if (error.response.data.mensagem != null) {
                                return HelperService.callMakeModalWithBtnsAndTitle("Erro ao apagar arquivos!", error.response.data.mensagem[0], 'error');
                            }
                            // return HelperService.callMakeToast("Erro ao apagar arquivos!", "error");
                            this.$toast.open({message: 'Erro ao apagar arquivos!', type: 'error', duration: 2000});
                        })
                    }
                })
            }
        },

        clearContabilFiles(nCodOp){
            if(this.selected.length == 0 || this.selected.length == this.listFiles.length){
                this.$swal({
                icon: "error",
                title: 'Deseja apagar TODOS os arquivos contábeis?',
                html: `Esta ação não poderá ser desfeita.`,
                type: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Apagar tudo',
                cancelButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.value) {
                        this.$emit("showOverlay", true);
                        UploadInsumosService.clearContabilFiles(this.getAllId(),
                        ).then((response) => {
                            if (response.status == 200) {
                                this.listFiles = []
                                this.$emit("cleanList");
                                // HelperService.callMakeToast("Todos os arquivos contribuições foram apagados com sucesso!", "success");
                                this.$toast.open({message: 'Todos os arquivos foram apagados com sucesso!', type: 'success', duration: 2000});
                                this.selected = [];
                                return this.$emit("showOverlay", false);
                            }
                        }).catch((error) => {
                            this.$emit("showOverlay", false);
                            console.log(error.response);
                            if (error.response.data.mensagem != null) {
                                return HelperService.callMakeModalWithBtnsAndTitle("Erro ao apagar arquivos contábeis!", error.response.data.mensagem[0], 'error');
                            }
                            // return HelperService.callMakeToast("Erro ao apagar arquivos!", "error");
                            this.$toast.open({message: 'Erro ao apagar arquivos contábeis!', type: 'error', duration: 2000});
                        })
                    }
                })
            } 
            else{
                this.$swal({
                icon: "error",
                title: 'Deseja apagar ' + this.selected.length + ' arquivos contábeis?',
                html: `Esta ação não poderá ser desfeita.`,
                type: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Apagar',
                cancelButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.value) {
                        this.$emit("showOverlay", true);
                        UploadInsumosService.clearContabilFiles(this.selected,
                        ).then((response) => {
                            if (response.status == 200) {
                                this.listFiles = []
                                this.$emit("cleanList");
                                // HelperService.callMakeToast("Os arquivos selecionados foram apagados com sucesso!", "success");
                                this.$toast.open({message: 'Os arquivos selecionados foram apagados com sucesso!', type: 'success', duration: 2000});
                                this.selected = [];
                                return this.$emit("showOverlay", false);
                            }
                        }).catch((error) => {
                            this.$emit("showOverlay", false);
                            console.log(error.response);
                            if (error.response.data.mensagem != null) {
                                return HelperService.callMakeModalWithBtnsAndTitle("Erro ao apagar arquivos!", error.response.data.mensagem[0], 'error');
                            }
                            // return HelperService.callMakeToast("Erro ao apagar arquivos!", "error");
                            this.$toast.open({message: 'Erro ao apagar arquivos!', type: 'error', duration: 2000});
                        })
                    }
                })
            }
        },

        clearFiscalFiles(nCodOp){
            if(this.selected.length == 0 || this.selected.length == this.listFiles.length){
                this.$swal({
                icon: "error",
                title: 'Deseja apagar TODOS os arquivos fiscais?',
                html: `Esta ação não poderá ser desfeita.`,
                type: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Apagar tudo',
                cancelButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.value) {
                        this.$emit("showOverlay", true);
                        this.$emit('blockStepper')
                        UploadICMSExclusaoService.clearFiscalFiles(this.getAllId(),
                        ).then((response) => {
                            if (response.status == 200) {
                                this.listFiles = []
                                this.$emit("cleanList");
                                // HelperService.callMakeToast("Todos os arquivos fiscais foram apagados com sucesso!", "success");
                                this.$toast.open({message: 'Os arquivos selecionados foram apagados com sucesso!', type: 'success', duration: 2000});
                                this.selected = [];
                                this.$emit("validateSpedPairing");
                                return this.$emit("showOverlay", false);
                            }
                        }).catch((error) => {
                            this.$emit("showOverlay", false);
                            console.log(error.response);
                            if (error.response.data.mensagem != null) {
                                return HelperService.callMakeModalWithBtnsAndTitle("Erro ao apagar arquivos!", error.response.data.mensagem[0], 'error');
                            }
                            // return HelperService.callMakeToast("Erro ao apagar arquivos!", "error");
                            this.$toast.open({message: 'Erro ao apagar arquivos!', type: 'error', duration: 2000});
                        })
                        
                    }
                })
            } 
            else{
                this.$swal({
                icon: "error",
                title: 'Deseja apagar ' + this.selected.length + ' arquivos fiscais?',
                html: `Esta ação não poderá ser desfeita.`,
                type: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Apagar',
                cancelButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.value) {
                        this.$emit("showOverlay", true);
                        this.$emit('blockStepper')
                        UploadICMSExclusaoService.clearFiscalFiles(this.selected,
                        ).then((response) => {
                            if (response.status == 200) {
                                this.selected = [];
                                this.$emit("cleanList");
                                // HelperService.callMakeToast("Os arquivos selecionados foram apagados com sucesso!", "success");
                                this.$toast.open({message: 'Os arquivos selecionados foram apagados com sucesso!', type: 'success', duration: 2000});
                                this.$emit("validateSpedPairing");
                                return this.$emit("showOverlay", false);
                            }
                        }).catch((error) => {
                            this.$emit("showOverlay", false);
                            console.log(error.response);
                            if (error.response.data.mensagem != null) {
                                return HelperService.callMakeModalWithBtnsAndTitle("Erro ao apagar arquivos!", error.response.data.mensagem[0], 'error');
                            }
                            // return HelperService.callMakeToast("Erro ao apagar arquivos!", "error");
                            this.$toast.open({message: 'Erro ao apagar arquivos!', type: 'error', duration: 2000});
                        })
                        
                    }
                })
            }
        },

        apagarI050Vazios() {
            this.$emit("showOverlay", true);
            RestituicaoService.apagarI050Vazios(this.nCodOp)
            .then((response) => {
                if (response.status == 200) {
                    // HelperService.callMakeToast("I050 apagados com sucesso !!", "success");
                    this.$toast.open({message: 'I050 apagados com sucesso !!', type: 'success', duration: 2000});
                    return this.$emit("showOverlay", false);
                }
            }).catch((error) => {
                this.$emit("showOverlay", false);
                console.log(error.response);
                if (error.response.data.mensagem != null) {
                    return HelperService.callMakeModalWithBtnsAndTitle("Erro ao apagar !", error.response.data.mensagem[0], 'error');
                }
                // return HelperService.callMakeToast("Erro ao apagar", "error");
                this.$toast.open({message: 'Erro ao apagar', type: 'error', duration: 2000});
            })    
        },

        apagarTudoExcel() {
            this.$swal({
                icon: "error",
                title: 'Deseja apagar o excel enviado ?',
                html: `Essa ação não poderá ser desfeita.`,
                type: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, desejo continuar!',
                cancelButtonText: `Cancelar`,
            })
                .then((result) => {
                    if (result.value) {
                        this.$emit("showOverlay", true);
                            UploadRestituicaoService.apagarExcel(
                            this.nCodOp,
                        ).then((response) => {
                            if (response.status == 200) {
                                this.hasExcelSended = false;
                                this.$emit("cleanList");
                                // HelperService.callMakeToast("Excel apagado com sucesso !!", "success");
                                this.$toast.open({message: 'Excel apagado com sucesso !!', type: 'success', duration: 2000});
                                return this.$emit("showOverlay", false);
                            }
                        }).catch((error) => {
                            this.$emit("showOverlay", false);
                            console.log(error.response);
                            if (error.response.data.mensagem != null) {
                                return HelperService.callMakeModalWithBtnsAndTitle("Erro ao apagar !", error.response.data.mensagem[0], 'error');
                            }

                            // return HelperService.callMakeToast("Erro ao apagar", "error");
                            this.$toast.open({message: 'Erro ao apagar', type: 'error', duration: 2000});
                        })
                    }
                })
        },             

        maskCnpj(cnpj) {
            return HelperService.maskCnpj(cnpj)
        },

        formatDate(dataRegistro, dataEnvio) {
            var date;
            if(dataRegistro != undefined) date = dataRegistro;
            if(dataEnvio != undefined) date = dataEnvio;

            return HelperService.formatDate(date)
        },

        select(){
            this.selected = [];
            if(!this.selectAll){
                for(let file in this.listFiles){
                    this.selected.push(this.listFiles[file].id);
                }
                console.log()
            }
        },
    },
}
</script>

<style scoped>
.colored-toast .swal2-icon-success {
    background-color: red !important;
}
</style>