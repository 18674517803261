import axios from "axios";
const API_URL = process.env.VUE_APP_URL_API;

class UploadRestituicaoService {

  async uploadFiles(files, tese, nCodOp, ignorarCnpj = false, validate60Meses = true) {
    let file = new FormData();
    file.append("Files", files);
    file.append("NCodOp", nCodOp);
    file.append("IgnorarCNPJ", ignorarCnpj); //validar depois pra passar true na modal de confirmação
    file.append("Validar60Meses",validate60Meses )

    console.log("file",file);
    return await axios.post(`${API_URL}/${tese}/upload/`, file);
  }

  uploadFilesFaturamento(files, archiveKind, idUser, dados) {
    let file = new FormData();
    files.forEach((element) => {
      file.append("File", element);
    });
    file.append("ArchiveKind", archiveKind);
    file.append("IdUser", idUser);
    file.append("NCodOp", dados.nCodOp);
    return axios.post(`${API_URL()}/oportunidade/leitura/`, file);
  }

  uploadFilesFaturamentoSefipExt(archiveKind, idUser, dados, inputSefipExt) {
    let file = new FormData();
    file.append("ArchiveKind", archiveKind);
    file.append("IdUser", idUser);
    file.append("NCodOp", dados.nCodOp);
    file.append("ValorCompensado", inputSefipExt);
    file.append(
      "MesReferencia",
      dados.fasesStatus.statusCompensacoes[
        dados.fasesStatus.statusCompensacoes.length - 1
      ].mesAnoReferencia
    );
    return axios.post(`${API_URL}/oportunidade/leitura/`, file);
  }

  getInsumos(nCodOp, passo) {
    let params = {
      NCodOp: nCodOp,
      Passo: passo,
    };
    return axios.get(`${API_URL}/Restituicao/getinsumos/`, {params});
  }

  getNotas(nCodOp, selecteds) {
    return axios.post(`${API_URL}/Restituicao/getnotas/`, {
      NCodOp: nCodOp,
      CodigosCta: selecteds,
    });
  }

  async getFilesSended(nCodOp) {
    let params = {
      NCodOp: nCodOp,
    };
    return await axios.get(`${API_URL}/Restituicao/getfilessended/`, {params});
  }

  // async apagarTudo(nCodOp) {
  //   let params = {
  //     NCodOp: nCodOp,
  //   };  
  //   var heads={ crossDomain: true, "Content-Type": "application/json" }
  //   return await axios.delete(`${API_URL}/Restituicao/deleteAllFilesSped/`, {params}, {headers:heads});  
  // }

  async apagarExcel(nCodOp) {
    let params = {
      NCodOp: nCodOp,
    };  
    var heads={ crossDomain: true, "Content-Type": "application/json" }
    return await axios.delete(`${API_URL}/Restituicao/deleteExcelFile/`, {params}, {headers:heads});  
  }
}

export default new UploadRestituicaoService();